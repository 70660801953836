import { useState, useRef } from "react";

import styled from "styled-components/macro";

import EmailGestion from "./email-gestion.component";
import PseudoGestion from "./pseudo-gestion.component";
import Units from "./units-gestion.component";
import PasswordGestion from "./password-gestion.component";
import LanguageGestion from "./language-gestion.component";

import { logout, setMe, useLogin } from "../../../../utils/auth.utils";
import colors from "../../../../themes/colors-v2.theme";
import { Section } from "../settings-styled.component";
import fonts from "../../../../themes/fonts.theme";
import Privacy from "../privacy.component";
import UnblockGestion from "../section-unblock.component";
import useLocale from "../../../../utils/locale/locale.hook";
import { useMediaQuery } from "react-responsive";
import { useIsPremium } from "../../../../utils/useUser.utils";
import { PrimaryButton } from "../../../../components/forms/primaryButton.component";
import { useRedirectUnsignedUser } from "../../../../utils/useRedirectUnsignedUser";
import { useOnClickOutside } from "../../../../utils/clickOutside.utils";

const SectionAccount = () => {
  useRedirectUnsignedUser();
  const { me } = useLogin();
  const locale = useLocale();
  const [activeTab, setActiveTab] = useState<"general" | "privacy" | "blocked">(
    "general"
  );
  const isDesktop = useMediaQuery({ query: "(min-width: 1000px)" });
  const isPremium = useIsPremium();
  const [open, setOpen] = useState(false);
  const languageMenuRef = useRef(null);

  useOnClickOutside([languageMenuRef], () => {
    setOpen(false);
  });

  const handleValidation = () => {
    setMe();
  };

  return (
    <Wrapper isPremium={isPremium}>
      <Header>
        <Row>
          <FilterButton
            active={activeTab === "general"}
            onClick={() => setActiveTab("general")}
          >
            {locale("global.settings.general")}
          </FilterButton>
          <FilterButton
            active={activeTab === "privacy"}
            onClick={() => setActiveTab("privacy")}
          >
            {locale("global.settings.privacy")}
          </FilterButton>
          <FilterButton
            active={activeTab === "blocked"}
            onClick={() => setActiveTab("blocked")}
          >
            {locale("global.settings.members_blocked")}
          </FilterButton>
        </Row>
      </Header>

      <div style={{ marginTop: isDesktop ? 20 : 4 }}>
        {activeTab === "general" && (
          <Container>
            <Section>
              <PseudoGestion me={me} onChange={handleValidation} />
            </Section>
            <Section>
              <EmailGestion me={me} onChange={handleValidation} />
            </Section>
            <Section>
              <PasswordGestion />
            </Section>
            <Section
              style={{ position: "relative", cursor: "pointer" }}
              ref={languageMenuRef}
              onClick={() => setOpen((prevState) => !prevState)}
            >
              <LanguageGestion isOpen={open} isFromRadar={false} isResponsive={false}/>
            </Section>
            <Section>
              <Units />
            </Section>

            {!isDesktop ? (
              <PrimaryButton
                style={{
                  background: "transparent",
                  borderColor: "white",
                  margin: "16px",
                }}
                size="large"
                onClick={logout}
              >
                {locale("account.logout")}
              </PrimaryButton>
            ) : null}
          </Container>
        )}
        {activeTab === "privacy" && <Privacy />}
        {activeTab === "blocked" && <UnblockGestion />}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ isPremium: boolean }>`
  margin-top: 20px;
  @media (max-width: 1000px) {
    margin-top: 0px;
    margin-bottom: ${({ isPremium }) => (isPremium ? "80px" : "120px")};
  }
`;

const Container = styled.div`
  box-sizing: border-box;
  max-width: 798px;
  margin: auto;
  width: 100%;
  height: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (max-width: 1000px) {
    box-sizing: border-box;
  }
`;

const Header = styled.div`
  text-transform: uppercase;
  font-size: 10px;
  font-family: ${fonts.semiBold.name};
  font-weight: ${fonts.semiBold.weight};
  color: ${colors.darkerGrey};
  padding: 0px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${colors.darkGradient};
  background-attachment: fixed;

  @media (max-width: 1000px) {
    padding: 0px;
  }
`;

const Row = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 8px;
  gap: 8px;

  @media (max-width: 1000px) {
    width: fit-content;
    margin-top: 14px;
    margin-bottom: 6px;
    height: 44px;
    width: 100%;
    margin: 0;
`;

const FilterButton = styled.button<{ active?: boolean }>`
  border: none;
  cursor: pointer;
  width: fit-content;
  background: transparent;
  color: ${({ active }) => (active ? colors.darkBlue : "white")};
  font-size: 12px;
  font-weight: 600;
  padding: 0 10px 10px 10px;
  text-align: center;
  border-bottom: ${({ active }) =>
    active ? `2px solid ${colors.darkBlue}` : ""};
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export default SectionAccount;
